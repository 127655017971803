import React, {useCallback, useMemo, useReducer, useEffect} from "react";
import axios from "axios";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import {useUpdateEffect} from "../utils/hooks";
import {axiosConfig} from "../utils/request";
import {dateFormats, dateTimeFormats} from "../utils/formats";
import {useAxiosRequest} from "use-axios-request";
import { find } from "lodash"; // yarn add axios lodash use-axios-request @umijs/hooks qs

const initialState = {
  isFetching: false,
  error: null,
  startDate: moment(),
  endDate: moment(),
  events: [],
  requestCounter: 0,
  spaceFilter: null,
  showSpaceFilter: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const buttonsText = {
  "today": "Avui",
  "month": "Mes",
  'week': "Setmana",
  'day': "Dia"
}

const Calendar = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionsRequest = axios.get("training/scheduling/calendar_events", {
          ...axiosConfig,
          params: {
            from: state.startDate.format(dateFormats.server),
            to: state.endDate.format(dateFormats.server)
          }
        });

        const events = []
        const [sessions] = await Promise.all([sessionsRequest])

        events.push(...sessions.data.data.flatMap((session) => (
          {
            id: `${session.id}`,
            title: `${session.title}`,
            start: session.starts_at_iso8601,
            end: session.ends_at_iso8601,
            allDay: false,
            //backgroundColor: "rgb(79 70 229 / var(--tw-bg-opacity))",
            //borderColor: "rgb(79 70 229 / var(--tw-bg-opacity))",
            url: session.enrollment_url,
            extendedProps: {remote:'true'},
            classNames: session.class
          }
        )));

        dispatch({
          type: 'updateState',
          payload: {
            events: events,
            isFetching: false,
            error: null
          }
        })
      } catch (e) {
        console.error(e)
        dispatch({
          type: 'updateState',
          payload: {
            events: [],
            isFetching: false,
            error: "Error al carregar la informació"
          }
        })
      }
    }

    dispatch({
      type: 'updateState',
      payload: {
        isFetching: true,
        error: null
      }
    });

    fetchData();
  }, [dispatch, state.requestCounter, state.startDate])

  const handleDateChange = ({view}) => {
    dispatch({
      type: 'updateState',
      payload: {
        startDate: moment(view.activeStart),
        endDate: moment(view.activeEnd)
      }
    })
  }

  const reload = useCallback(() => {
    dispatch({
      type: 'updateState',
      payload: {
        requestCounter: state.requestCounter + 1
      }
    });
  }, [state.requestCounter]);

  return (
    <>
      <div className="fc-wrapper-full-height">
        <FullCalendar
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,dayGridMonth"
          }}
          firstDay={1}
          height='100%'
          locale="ca"
          plugins={[dayGridPlugin,timeGridPlugin]}
          events={state.events}
          eventDidMount={
            function(arg){
              arg.el.setAttribute('data-turbo-frame', 'modal');
            }
          }
          datesSet={handleDateChange}
          fixedWeekCount={false}
          buttonText={buttonsText}
        />
      </div>
    </>
  );
};

export default Calendar;
