import qs from "qs";

export const axiosConfig = {
    baseURL: "/",
    withCredentials: true,
    xsrfCookieName: "CSRF-TOKEN",
    xsrfHeaderName: "X-CSRF-Token",

    paramsSerializer: (params) => {
        return qs.stringify(params, { encodeValuesOnly: true });
    }
};
