// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import Calendar from "./components/Calendar";

// Custom JS files
import 'alpine-turbo-drive-adapter'
import Alpine from "alpinejs"

window.Alpine = Alpine
Alpine.start()

window.Cuesb = window.Cuesb || {};
window.Cuesb.ReactComponents = { ...{ Calendar } };
